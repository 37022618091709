.modal .modal-content .modal-header {
  display: block;
}

.modal .modal-content .modal-header .modal-title {
  font-weight: 600;
  font-size: 1.136em;
  margin-bottom: 0;
}

.modal-header .close {
  position: absolute;
  right: 20px;
  top: 14px;
  font-weight: 100;
}

.modal-title {
  display: block;
  font-size: 1.989em;
  line-height: 1.989em;
  font-weight: 400;
  margin-bottom: 20px;
}

.modal {
  p {
    font-size: 1.065em;
    line-height: 1.847em;
    margin-top: 0;
    margin-bottom: 13px;
    font-weight: 300;
  }
}

/*Strip*/
.modal-strip {
  background-color: $black;
  bottom: 0;
  color: $white;
  left: 0;
  padding: 20px;
  position: fixed;
  width: 100%;
  display: inline-block;
  transform: translate3d(0px, 200px, 0px);
  transition: all 0.7s ease 0s;
  z-index: 999 !important;

  .btn,
  h5,
  h4,
  h3,
  h2,
  h1 {
    margin-bottom: 0 !important;
  }

  a,
  a:hover,
  a:active,
  a:visited {
    color: $white;
    text-decoration: underline;
  }

  &.modal-top {
    bottom: auto;
    top: 0;
    transform: translate3d(0px, -200px, 0px);
  }

  &.modal-left {
    bottom: auto;
    top: auto;
    left: 0;
    right: auto;
    width: 30%;
    transform: none;
  }

  &.modal-right {
    transform: translate3d(0px, 0px, 0px);
    bottom: auto;
    top: 0;
  }

  &.modal-active {
    transform: translate3d(0px, 0px, 0px);
  }
}

@include media-breakpoint-down(lg) {
  .modal-strip.modal-active .vertical-align {
    top: 0 !important;
    transform: translateY(0px) !important;
  }
}

/* Zoom-out effect*/
.mfp-zoom-out {

  /* start state */
  .mfp-content {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-content {
      opacity: 1;
      transform: scale(1);
    }

    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {
    .mfp-content {
      transform: scale(1.3);
      opacity: 0;
    }

    &.mfp-bg {
      opacity: 0;
    }
  }
}

.mfp-ready .modal {
  background: #fff;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  max-width: 580px;
  padding: 40px;
  position: relative;
  z-index: 1050;
}

.modal .video-wrap video,
.modal .iframe-wrap iframe,
.modal .audio-wrap audio {
  width: 100%;
}

.modal iframe {
  margin-bottom: -10px;
}

//Dark version
.dark {
  .mfp-bg {
    background-color: $gray-900;
}

.mfp-ready .modal {
    background-color: $gray-900;
}

.modal-content {
  background-color: $gray-900;
}
.modal-header {
  border-bottom-color: #444444;
}

.modal-footer {
  border-top-color: #444444;
}
}



