.sidebar-menu {
    font-family: $navbar-nav-link-font-family;

    label {
        display: block;
        text-transform: uppercase;
        font-size: 0.994em;
        font-weight: 700;
        letter-spacing: .5px;

        &~label {
            margin-top: 30px;
        }
    }

    li {
        &+li {
            margin-top: 8px;
        }

        padding: 0;
        display: block;
        font-size: 0.994em;

        a {
            color: $body-color;

            >i {
                margin-right: 10px;
                width: 16px;
            }

            display: block;
        }
    }
}

.sidebar-modern {
    position: relative;
    .sidebar-menu {
        padding: 0 10px;
    }
}