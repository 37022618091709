$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
/*

Polo - Multipurpose HTML5 Template
Version: 5.9.9
Website: https://inspirothemes.com/themes/polo
License: https://inspirothemes.com/themes/polo/license.html
Inspiro Themes (https://inspirothemes.com)
Author: INSPIRO - Ardian Berisha
Copyright 2021

*/

//Google Fonts
@if variable-exists(project-font-url) {
  @import url($project-font-url);
} else {
  @import url('https://fonts.googleapis.com/css?family=Poppins:100,200,400,500,600,700,800|Nunito:300,400,600,700,800');
}

// Bootstrap Configuration
@import "@oimmei/pololight/bootstrap/scss/functions";

// Polo variables
@import "../../../../../assets/scss/poloVariables";
@import "variables";

// Bootstrap Configuration
@import "@oimmei/pololight/bootstrap/scss/variables";
@import "@oimmei/pololight/bootstrap/scss/mixins";
//@import "@oimmei/pololight/bootstrap/scss/utilities";

// Mixins
@import "mixins";

// Bootstrap optional
//@import "bootstrap";

// Polo
//@import "core";
//@import "layout";

// utilities
@import "utilities";

// widgets
@import "widgets";

// Base
@import "base/include";

// Vendor
@import "vendor";

// Vendor
@import "components";

// Elements
@import "elements/include";

// Oimmei: customizations.
@import "customization/oi";
